export const columns = [
// {
//     title: '发货单',
//     dataIndex: 'id',
//     key: 'id',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '（0代表三菱的发货单）所属经销商id',
//     dataIndex: 'orderBelongsId',
//     key: 'orderBelongsId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '经销商id',
//     dataIndex: 'dealerId',
//     key: 'dealerId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
  {
    title: '采购单号',
    dataIndex: 'purchaseCode',
    key: 'purchaseCode',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },
  {
    title: '发货单号',
    dataIndex: 'shipCode',
    key: 'shipCode',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },
// {
//     title: '省_id',
//     dataIndex: 'provinceId',
//     key: 'provinceId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '省',
//     dataIndex: 'provinceName',
//     key: 'provinceName',
//     /*width:150,*/
//     align:'center',
//    // ellipsis:true
// },
// {
//     title: '城市_id',
//     dataIndex: 'cityId',
//     key: 'cityId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '市',
//     dataIndex: 'cityName',
//     key: 'cityName',
//     /*width:150,*/
//     align:'center',
//    // ellipsis:true
// },
// {
//     title: '区_id',
//     dataIndex: 'areaId',
//     key: 'areaId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '区',
//     dataIndex: 'areaName',
//     key: 'areaName',
//     /*width:150,*/
//     align:'center',
//    // ellipsis:true
// },
//   {
//     title: '收货人',
//     dataIndex: 'consignee',
//     key: 'consignee',
//     /*width:150,*/
//     align: 'center',
//     // ellipsis:true
//   },
//   {
//     title: '收货人电话',
//     dataIndex: 'phone',
//     key: 'phone',
//     /*width:150,*/
//     align: 'center',
//     // ellipsis:true
//   },
//   {
//     title: '详细街道地址',
//     dataIndex: 'address',
//     key: 'address',
//     /*width:150,*/
//     align: 'center',
//     //  ellipsis:true
//     scopedSlots: {customRender: 'address'}
//   },
  {
    title: '数量',
    dataIndex: 'shipNum',
    key: 'shipNum',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },
  {
    title: '实际支付价',
    dataIndex: 'actualPrice',
    key: 'actualPrice',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },
  {
    title: '供应商名称',
    dataIndex: 'supplierName',
    key: 'supplierName',
    /*width:150,*/
    align: 'center',
    //ellipsis:true
  },
  // {
  //   title: '仓库名称',
  //   dataIndex: 'warehouseName',
  //   key: 'warehouseName',
  //   /*width:150,*/
  //   align: 'center',
  //   // ellipsis:true
  // },
  {
    title: '折扣',
    dataIndex: 'discount',
    key: 'discount',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   /*width:150,*/
  //   align: 'center',
  //   // ellipsis:true
  // },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align: 'center',
    // ellipsis:true
  },

]
